<template>
    <section class="main">
        <h1>{{ $route.name }}</h1>
        <p v-if="$attrs.notfound">The page you tried to hit doesn't exist. Instead, I'll just slap the home content here, right after this horizontal dashed rainbow line.</p>
        <hr v-if="$attrs.notfound" class="fancy" />
        <p>That's the landingpage. Currently there's not much to check out since the page is more of a playground to fiddle around with. One day you'll get to know everything about me - Exakayem.</p>
    </section>
</template>

<script>
</script>